import { Component, OnInit, HostListener, ElementRef, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Output()
  scrollEvent: EventEmitter<string> = new EventEmitter<string>();

  public menu : boolean = false;
  public windowHeight = window.innerHeight;
  public isLaptop = !window.matchMedia('(max-width: 600px)').matches;

  constructor() { }

  ngOnInit() {}

  displayMenu(){
    setTimeout(() => {
      this.menu = !this.menu;

      if(!this.isLaptop) document.body.style.overflow = 'hidden';
    }, 10);
  }

  @HostListener('document:click')
  clickout() {
    this.menu = false;
    document.body.style.overflow = 'auto';
  }

  scroll(el: string) {
    this.scrollEvent.emit(el);
  }
}
