import { Skill } from 'src/models/skill.model';

export const SKILL_LIST: Skill[] = [
    {
        title: 'Expert GraphQL',
        description: 'Création d\'interfaces distribuées',
        detailTitle: 'Mon domaine d\'expertise :',
        details: ['Ecriture de schéma GraphQL', 'Migration d\'application', 'Implétentation de la solution', 'Fédération dans un envronnement microservice'],
        urlLogo: 'assets/logo/graphql.png'
    },
    {
        title: 'Architecture logiciel',
        description: 'Conception de systèmes scalables, intéropérables et résilients.',
        detailTitle: 'Les concepts que je mets en place :',
        details: ['Identification des besoins', 'Conception architecturale', 'Analyse qualité', 'Plan de déploiement'],
        urlLogo: 'assets/logo/arch.png'
    },
    {
        title: 'Développeur Backend',
        description: 'Développement d\'applications complexes.',
        detailTitle: 'Mes compétences :',
        details: ['Respect des bonnes pratiques', 'Interfaçage à des système externe', 'Gestion des authentifications et autorisations', 'Extraction de logs et de métriques'],        
        urlLogo: 'assets/logo/dev.png'
    }
];