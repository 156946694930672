import { Component, OnInit, Input } from '@angular/core';
import { Xp } from 'src/models/xp.model';

@Component({
  selector: 'app-xp',
  templateUrl: './xp.component.html',
  styleUrls: ['./xp.component.scss']
})
export class XpComponent implements OnInit {

  @Input()
  xp: Xp;

  constructor() { }

  ngOnInit() {
  }

}
