import { Component, OnInit, ElementRef } from '@angular/core';
import { Terminal } from 'src/models/terminal.model';
import { TERMINAL_LIST } from '../../mocks/terminal-list-mock';


@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit {

  public terminals : Terminal[] = TERMINAL_LIST;
  public terminalsDisplay : Terminal[] = [];

  constructor() {}

  ngOnInit() {
    this.displayCmd(0);
  }


  displayCmd(index : number){

    this.terminalsDisplay[index] = { cmd:'', output: [], speed:0, delay:0};
    var i = 0;

    setTimeout(() => {

      var id = setInterval(async () => {
      
        if(i == this.terminals[index].cmd.length){

          clearInterval(id);

          for(let i = 0; i < this.terminals[index].output.length; i++){

            await this.displayOneOutput(index, i);
          }
          if(index+1 < this.terminals.length)this.displayCmd(index+1)

          clearInterval(id);
        }
        else{
          this.terminalsDisplay[index].cmd+=this.terminals[index].cmd[i];
          i++;
        }
        
      }, this.terminals[index].speed)

    }, this.terminals[index].delay)
  }

  async displayOneOutput(index, i){

    return new Promise(resolve => {
      setTimeout(() => {
          this.terminalsDisplay[index].output[i] = this.terminals[index].output[i];
          resolve(true);
      }, this.terminals[index].output[i].delay);
    });
  }
}
