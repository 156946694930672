import { Injectable } from '@angular/core';
import { XP_LIST } from 'src/mocks/xp-list.mock';
import { Xp } from 'src/models/xp.model';

@Injectable({
  providedIn: 'root'
})
export class XpService {

  public xps : Xp[] = XP_LIST;

  constructor() { }
}
