import { ToolGroup } from "src/models/tool.model";

export const TOOL_LIST: ToolGroup[] = [
  {
    title: 'Langages',
    tools: [
      {
        name: 'Javascript',
        urlLogo: 'assets/logo/js.png',
      },
      {
        name: 'Typescript',
        urlLogo: 'assets/logo/ts.png',
      },
      {
        name: 'HTML/CSS',
        urlLogo: 'assets/logo/html.png',
      },
      {
        name: 'Java',
        urlLogo: 'assets/logo/java.png',
      },
      {
        name: 'Python',
        urlLogo: 'assets/logo/py.png',
      },
      {
        name: 'C/C++',
        urlLogo: 'assets/logo/c.png',
      },
      {
        name: 'Groovy',
        urlLogo: 'assets/logo/groovy.png',
      }
    ]
  },
  {
    title: 'Back',
    tools: [
      {
        name: 'NestJS',
        urlLogo: 'assets/logo/nest.png',
      },
      {
        name: 'NodeJS',
        urlLogo: 'assets/logo/node.png',
      },
      {
        name: 'Spring',
        urlLogo: 'assets/logo/spring.png',
      },
      {
        name: 'GraphQL',
        urlLogo: 'assets/logo/gql.png',
      },
      {
        name: 'API REST',
        urlLogo: 'assets/logo/rest.png',
      },
      {
        name: 'WebSocket',
        urlLogo: 'assets/logo/ws.png',
      },
      {
        name: 'CASL',
        urlLogo: 'assets/logo/casl.png',
      },
      {
        name: 'Jest',
        urlLogo: 'assets/logo/jest.png',
      },
      {
        name: 'JUnit',
        urlLogo: 'assets/logo/junit.png',
      },
    ]
  },
  {
    title: 'Base de données',
    tools: [
      {
        name: 'MongoDB',
        urlLogo: 'assets/logo/mongo.png',
      },
      {
        name: 'MySQL',
        urlLogo: 'assets/logo/mysql.png',
      },
      {
        name: 'Redis',
        urlLogo: 'assets/logo/redis.png',
      },
      {
        name: 'Neo4J',
        urlLogo: 'assets/logo/neo4j.png',
      },
      {
        name: 'SQL',
        urlLogo: 'assets/logo/sql.png',
      },
      {
        name: 'Firebase',
        urlLogo: 'assets/logo/firebase.png',
      }
    ]
  },
  {
    title: 'Devops',
    tools: [
      {
        name: 'Jenkins',
        urlLogo: 'assets/logo/jenkins.png',
      },
      {
        name: 'Git',
        urlLogo: 'assets/logo/git.png',
      },
      {
        name: 'Docker',
        urlLogo: 'assets/logo/docker.png',
      },
      {
        name: 'Maven',
        urlLogo: 'assets/logo/maven.png',
      },
      {
        name: 'NPM',
        urlLogo: 'assets/logo/npm.png',
      },
      {
        name: 'Swagger',
        urlLogo: 'assets/logo/swagger.png',
      },
      {
        name: 'Fédération Apollo',
        urlLogo: 'assets/logo/apollo.png',
      },
      {
        name: 'Kong Gateway',
        urlLogo: 'assets/logo/kong.png',
      },
      {
        name: 'OpenID Connect',
        urlLogo: 'assets/logo/oidc.png'
      },
    ]
  },
  {
    title: 'Front',
    tools: [
      {
        name: 'React',
        urlLogo: 'assets/logo/react.png',
      },
      {
        name: 'Angular',
        urlLogo: 'assets/logo/angular.png',
      },
      {
        name: 'Vue',
        urlLogo: 'assets/logo/vue.png',
      },
      {
        name: 'Android Studio',
        urlLogo: 'assets/logo/android.png',
      },
      {
        name: 'Qt',
        urlLogo: 'assets/logo/qt.png',
      },
      {
        name: 'Figma',
        urlLogo: 'assets/logo/figma.png'
      }
    ]
  },
  {
    title: 'Web3',
    tools: [
      {
        name: 'Solidity',
        urlLogo: 'assets/logo/solidity.png',
      },
      {
        name: 'Ganache',
        urlLogo: 'assets/logo/ganache.png',
      },
      {
        name: 'Web3.js',
        urlLogo: 'assets/logo/web3.jpg',
      },
      {
        name: 'Ethereum ERC20/ERC721',
        urlLogo: 'assets/logo/eth.png',
      },
    ]
  }
]