import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SkillService } from 'src/services/skill.service';
import { Skill } from 'src/models/skill.model';
import { ProjectService } from 'src/services/project.service';
import { Project } from 'src/models/project.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Subject } from 'rxjs';
import { ContactAnimation } from '../animations';
import { element } from 'protractor';
import { ToolGroup } from 'src/models/tool.model';
import { ToolService } from 'src/services/tool.service';
import { Xp } from 'src/models/xp.model';
import { XpService } from 'src/services/xp.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  animations: [
    ContactAnimation.inOutContact
  ]
})
export class PortfolioComponent implements OnInit {

  public skills : Skill[];
  public tools: ToolGroup[];
  public projects : Project[];
  public xps: Xp[];
  public contact : number = 0;
  public contactSlide : string[] = ['Discussion', 'Projet professionnel', 'Avis sur le site']
  public contactForm : FormGroup;
  public direction = 'left'

  @ViewChild('contactsection', {static:true}) contactsection:ElementRef;
  @ViewChild('projectsection', {static:true}) projectsection:ElementRef;
  @ViewChild('skillsection', {static:true}) skillsection:ElementRef;
  @ViewChild('accueilsection', {static:true}) accueilsection:ElementRef;


  constructor(private formBuilder : FormBuilder, private skillService: SkillService, private projectService : ProjectService, private toolService: ToolService, private xpService: XpService) {
    this.skills = skillService.skills;
    this.tools = toolService.tools;
    this.projects = projectService.projects;
    this.xps = xpService.xps;
    this.contactForm = this.formBuilder.group({});
  }

  ngOnInit() {

    document.getElementById("introduction-container").style.height = (window.innerHeight - 80) + 'px';
    window.addEventListener("resize", this.displayWindowSize);
  }

  displayWindowSize(){
    if(!window.matchMedia('(max-width: 1000px)').matches){
      document.getElementById("introduction-container").style.height = (window.innerHeight - 80) + 'px';
    }
  }

  getStyleForProject(index : number){

    if(window.matchMedia('(max-width: 1000px)').matches)
      return 'column';
    return index % 2 == 0 ? 'row' : 'row-reverse';
  }

  changeContact(event){

    var to = event.target.value;
    var from = this.contact;

    if(to == 1){

      if(from == 0)
        this.direction = 'left';
      else if(from == 2)
        this.direction = 'right';
    }
    if(to == 0)
      this.direction = 'left';
    if(to == 2)
      this.direction = 'right';    

    setTimeout(() => {
      this.contact = to;
    }, 20);
  }

  scroll(el: string) {

    switch(el){

      case 'contactsection': this.contactsection.nativeElement.scrollIntoView({behavior: "smooth"}); break;
      case 'accueilsection': window.scrollTo({top: 0, behavior: 'smooth'}); break;
      case 'skillsection': this.skillsection.nativeElement.scrollIntoView({behavior: "smooth"}); break;
      case 'projectsection' : this.projectsection.nativeElement.scrollIntoView({behavior: "smooth"}); break;
    }
  }


  mail(){
    window.location.assign('mailto:martin.bruel999@gmail.com');
  }



}
