import { Injectable } from '@angular/core';
import { Skill } from 'src/models/skill.model';
import { SKILL_LIST } from '../mocks/skill-list.mock';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  public skills : Skill[] = SKILL_LIST;

  constructor() { }
}
