import { Component, OnInit, Input, ViewChild, ContentChild, ElementRef } from '@angular/core';
import { Project } from 'src/models/project.model';
import { VideoService } from 'src/services/video.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  @Input()
  project: Project;

  @Input()
  public index: number;

  public playOn : boolean = false;

  public position : string = 'bas';

  constructor(private videoService : VideoService) {
    
  }

  ngOnInit() {

    if(this.project.urlVideo.length != 0){
      this.videoService.addVideoId(this.index);
      window.addEventListener('scroll', this.onScrollTrigered.bind(this));
    }
    
  }

  onScrollTrigered(){

    var video = this.videoService.getIndex(this.index);
    var position = video.getBoundingClientRect();
    
    // checking whether fully visible
    if(position.top >= 0 && position.bottom <= window.innerHeight && !this.playOn) {

      video.muted = true;
      video.play();
      this.playOn = true;
    }
    
    // checking for invisibility
    if(position.bottom > window.innerHeight && this.playOn) {

      video.pause();
      this.playOn = false;
      this.position = 'bas'
    }

    if(position.top < 0 && this.playOn){

      video.pause();
      this.playOn = false;
      this.position = 'haut'
    }
  }

  onNavigate(url){
    window.open(url);
  }

}
