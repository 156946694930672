import { Injectable } from '@angular/core';
import { TOOL_LIST } from 'src/mocks/tool-list.mock';
import { ToolGroup } from 'src/models/tool.model';

@Injectable({
  providedIn: 'root'
})
export class ToolService {

  public tools : ToolGroup[] = TOOL_LIST;

  constructor() { }
}
