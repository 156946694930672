import { Terminal } from 'src/models/terminal.model';

export const TERMINAL_LIST: Terminal[] = [

    {
        cmd: 'sudo apt-get install martin-bruel',
        output : [
            {
                line: 'Installation de martin-bruel en cours...',
                delay: 200  
            },
            {
                line: 'Recherche des formations suivies en cours... Fait',
                delay: 2000
            },
            {
                line: 'Les formations suivantes ont été suivies par martin-bruel:',
                delay: 100
            },
            {
                line: '&nbsp;&nbsp;&nbsp;&nbsp;Lycée des Arènes Toulouse (2017) - IUT Paul Sabatier Toulouse(2019) - Polytech Nice (2022)',
                delay: 200
            },
            {
                line: '3 Validées, 0 en cours',
                delay: 1000
            }, 
            {
                line: '-----------------------------',
                delay: 20
            },
            {
                line: 'Installation des modules en cours...',
                delay: 20
            },
            {
                line: 'Chargement du module de compétence... Fait',
                delay: 1200
            },
            {
                line: 'Chargement du module de projet... Fait',
                delay: 720
            },
            {
                line: 'Chargement du module de contact... Fait',
                delay: 1200
            },
            {
                line: 'Installation terminée en 2s20',
                delay: 20
            },
            {
                line: '&nbsp;',
                delay: 1300
            },
        ],
        speed:50,
        delay:2300,
    },
    {
        cmd:'martin-bruel run',
        output:[
            {
                line: 'Bienvenue sur www.martinbruel.fr !',
                delay: 700
            },
        ],
        speed:50,
        delay:2300,
    }
];

/*
$ sudo apt-get install martin-bruel

Reading course lists... Done
The following courses was take by martin-bruel:
	toulouse-arenes-lycee2.0.17 toulouse-paulsabatier-iut2.0.19 nice-unice-polytech2.0.22
2 done, 1 in progress.
Need to get a little bit of your time.
Do you want to continue? [Y/n] y
GET:1 https://arenes.mon-ent-occitanie.fr bacS toulouse-arenes-bac2.0.17 [3 years]
GET:2 https://www.univ-tlse3.fr/dut-informatique informatique&gestion toulouse-paulsabatier-iut2.0.19 [2 years]
GET:3 http://www.polytech.unice.fr/ ingénieur_informatique nice-unice-polytech2.0.22 [3 years]
Fetched lot of skills in 8years
Reading package lists... Done

$ martin-bruel run
[compétences] [projets] [contact]
*/