import { Injectable } from '@angular/core';
import { Project } from 'src/models/project.model';
import { PROJECT_LIST } from '../mocks/project-list.mock';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public projects : Project[] = PROJECT_LIST;

  constructor() { }
}