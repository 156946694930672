import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})




export class FooterComponent implements OnInit {

  @Output()
  scrollEvent: EventEmitter<string> = new EventEmitter<string>();

  height : number;

  constructor() {}

  ngOnInit() {}

  scroll(el: string) {
    this.scrollEvent.emit(el);
  }

  isLaptop(){
    return !window.matchMedia('(max-width: 600px)').matches;
  }


}
