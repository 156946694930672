import { Xp } from "src/models/xp.model";

export const XP_LIST: Xp[] = [
  {
    ent: '4AXES',
    title: 'Développeur analyste',
    urlLogo: 'assets/logo/4axes.jpg',
    date: '2019',
    tools: [
      {
        name: 'C++',
        urlLogo: 'assets/logo/c++.png',
      },
      {
        name: 'Qt',
        urlLogo: 'assets/logo/qt.png',
      },
      {
        name: 'SQL',
        urlLogo: 'assets/logo/sql.png',
      },
      {
        name: 'MySQL',
        urlLogo: 'assets/logo/mysql.png',
      }
    ]
  },
  {
    ent: 'Continental',
    title: 'Ingénieur devops ADAS',
    urlLogo: 'assets/logo/continental.png',
    date: '2021',
    tools: [
      {
        name: 'Jenkins',
        urlLogo: 'assets/logo/jenkins.png',
      },
      {
        name: 'Groovy',
        urlLogo: 'assets/logo/groovy.png',
      },
      {
        name: 'Python',
        urlLogo: 'assets/logo/py.png',
      }
    ]
  },
  {
    ent: 'STMicroelectronics',
    title: 'Architecte backend',
    urlLogo: 'assets/logo/st.png',
    date: '2022',
    tools: [
      {
        name: 'Typescript',
        urlLogo: 'assets/logo/ts.png',
      },
      {
        name: 'NestJS',
        urlLogo: 'assets/logo/nestjs.png',
      },
      {
        name: 'Jest',
        urlLogo: 'assets/logo/jest.png',
      },
      {
        name: 'NPM',
        urlLogo: 'assets/logo/npm.png',
      },
      {
        name: 'MongoDB',
        urlLogo: 'assets/logo/mongo.png',
      },
      {
        name: 'REDIS',
        urlLogo: 'assets/logo/redis.png',
      },
      {
        name: 'REST API',
        urlLogo: 'assets/logo/rest.png',
      },
      {
        name: 'GraphQL',
        urlLogo: 'assets/logo/gql.png',
      },
      {
        name: 'CASL',
        urlLogo: 'assets/logo/casl.png',
      },
      {
        name: 'Swagger',
        urlLogo: 'assets/logo/swagger.png',
      },
      {
        name: 'Docker',
        urlLogo: 'assets/logo/docker.png',
      },
    ]
  }
]