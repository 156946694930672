import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatButtonModule} from '@angular/material/button';
import { SkillComponent } from './skill/skill.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';
import { ProjectComponent } from './project/project.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ContactProjectComponent } from './contact/contact-project/contact-project.component';
import { ContactReviewComponent } from './contact/contact-review/contact-review.component';
import { ContactChatComponent } from './contact/contact-chat/contact-chat.component';
import { TerminalComponent } from './terminal/terminal.component';
import { ToolComponent } from './tool/tool.component';
import { XpComponent } from './xp/xp.component';
@NgModule({
  declarations: [
    AppComponent,
    SkillComponent,
    PortfolioComponent,
    ToolbarComponent,
    FooterComponent,
    ProjectComponent,
    ContactProjectComponent,
    ContactReviewComponent,
    ContactChatComponent,
    TerminalComponent,
    ToolComponent,
    XpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
