import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from 'src/services/contact.service';
import { trigger, transition, style, animate, keyframes, stagger, query } from '@angular/animations';
import { ContactAnimation } from 'src/app/animations';

@Component({
  selector: 'app-contact-chat',
  templateUrl: './contact-chat.component.html',
  styleUrls: ['../contact.scss'],
  animations: [
    ContactAnimation.inOutLoader,
    ContactAnimation.inButton
  ]
})
export class ContactChatComponent implements OnInit {

  checkoutForm : FormGroup;
  formSubmitted = false;
  loading =false;

  constructor(private formBuilder : FormBuilder, private contactService : ContactService) {

    this.checkoutForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      mail: [null, [Validators.required, Validators.email]],
      subject: [null, [Validators.required]],
      body: [null],
    })
    contactService.loading$.subscribe( (loading) => {

      this.loading = loading;
      if(!loading){
        this.checkoutForm.reset();
        this.formSubmitted = false;
      }
    });
  }

  ngOnInit() {}

  onSubmit(data){

    this.formSubmitted = true;

    if(this.checkoutForm.valid){

      this.contactService.sendMessage(data, 'Discussion');
    }
  }

  validation(id :string){
    if(!this.checkoutForm.controls[id].valid && this.formSubmitted)
      return 'invalide';
    return ' ';
  }

}
