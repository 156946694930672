import { trigger, query, transition, style, animate, keyframes } from '@angular/animations';

export const ContactAnimation = {
    inOutLoader : trigger('inOutLoader', [
        transition(':enter', [
            query('.loader', style({borderLeft: 'solid 2px #424242',borderBottom: 'solid 2px #424242', borderRight: 'solid 2px #424242', borderTop: 'solid 2px #1c89ff', opacity:0})),
            query('.check', style({transform: 'translate(-4px,100px)', opacity:0})),

            query('.loader', animate('3s ease-in-out', keyframes([
                style({opacity:1, offset: 0.1}),
                style({borderLeft: 'solid 2px #ffffff00',borderBottom: 'solid 2px #ffffff00', borderRight: 'solid 2px #ffffff00', borderTop: 'solid 2px #ffffff00',opacity:1, transform: 'rotate(2080deg)', offset: 0.99}),
                style({borderLeft: 'solid 2px #5199e4',borderBottom: 'solid 2px #5199e4', borderRight: 'solid 2px #5199e4', borderTop: 'solid 2px #5199e4',opacity:1, transform: 'rotate(1080deg)', offset: 1})
            ])
            )),
            query('.check', animate('300ms ease-in-out', style({transform: 'translate(-4px,4px)', opacity:1}))
            )
        ]),
        transition(':leave', [
          query('.loader', animate('300ms ease-in', style({opacity: 0})))
        ]),
    ]),
      
    inButton : trigger('inButton', [
        transition(':enter', [
            style({opacity: 0}),
            animate('300ms ease-in', style({opacity: 1}))
        ])
    ]),
    inOutContact : trigger('inOutContact', [
        transition('void => right', [
          style({transform: 'translateX(-200%)'}),
          animate('300ms ease-in', style({transform: 'translateX(0%)'}))
        ]),
        transition('void => left', [
          style({transform: 'translateX(200%)'}),
          animate('300ms ease-in', style({transform: 'translateX(0%)'}))
        ]),
        transition('right => void', [
          animate('300ms ease-in', style({transform: 'translateX(-200%)'}))
        ]),
        transition('left => void', [
          animate('300ms ease-in', style({transform: 'translateX(200%)'}))
        ]),
      ])
}