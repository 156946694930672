import { Injectable } from '@angular/core';
import { discordUrl, httpOptionsBase } from '../configs/server.config';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';




@Injectable({
    providedIn: 'root'
  })
  export class ContactService {

    private contactUrl = discordUrl;
    private httpOptions = httpOptionsBase;

    public loading$ : Subject<boolean> = new Subject<boolean>();

    constructor(private http: HttpClient, private router : ActivatedRoute) {}

    sendMessage(message, type){

      this.loading$.next(true);
      setTimeout(() => {
        
        console.log('submit : ', message);

        var description = message.body;
        var mail = message.mail;
        var name = message.name;
        var title = '';
        var avatar_url = '';

        if(message.subject)
          title = message.subject;

        if(message.type)
          title = message.type + ' : ' + title;

        var params : any = {
            username: "martinbruel.fr",
            avatar_url: avatar_url,
            content: type,
            embeds: [{
              title: title,
              description: description,
              color: 4186466,
              footer: {
                text: "Email : " + mail
              },
              author: {
                name: name
              }
            }],
        };
        
        this.http.post<any>(this.contactUrl, params, this.httpOptions).subscribe(() => console.log("Message envoyé"));

        this.loading$.next(false);
      }, 4500);
    }


}