import { Component, OnInit, Input } from '@angular/core';
import { Tool, ToolGroup } from 'src/models/tool.model';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.scss']
})
export class ToolComponent implements OnInit {

  @Input()
  group: ToolGroup;

  constructor() { }

  ngOnInit() {
  }

}
