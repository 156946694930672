import { Project } from 'src/models/project.model';

export const PROJECT_LIST: Project[] = [
    {
        title: 'Login Session Manager',
        description: 'Portail de gestion de sessions distantes. Cette application orientée microservices est distrubée sur différentes régions et intéragit avec les fermes de calcul de STMicroelectronics. Aussi, elle mets à disposition un graph fédéré pour abstraire et simplifier les intéractions avec le backend.',
        urlVideo: '',
        urlImage: 'assets/image/st.png',
        type: 'laptop',
        logo: 'assets/logo/st.png',
        team: 'Rahul Arora, Timothée Benoist, Laurent Farrugia, Romain Grindler',
        urlSourceCode: '',
        url: '',
        tool: [
            {
                name: 'Typescript',
                urlLogo: 'assets/logo/ts.png',
            },
            {
                name: 'NestJS',
                urlLogo: 'assets/logo/nest.png',
            },
            {
                name: 'Jest',
                urlLogo: 'assets/logo/jest.png',
            },
            {
                name: 'NPM',
                urlLogo: 'assets/logo/npm.png',
            },
            {
                name: 'Figma',
                urlLogo: 'assets/logo/figma.png',
            },
            {
                name: 'Vue',
                urlLogo: 'assets/logo/vue.png',
            },
            {
                name: 'REST API',
                urlLogo: 'assets/logo/rest.png',
            },
            {
                name: 'GraphQL',
                urlLogo: 'assets/logo/gql.png'
            },
            {
                name: 'Fédération Apollo',
                urlLogo: 'assets/logo/apollo.png'
            },
            {
                name: 'OpenID Connect',
                urlLogo: 'assets/logo/oidc.png'
            },
            {
                name: 'CASL',
                urlLogo: 'assets/logo/casl.png'
            },
            {
                name: 'MongoDB',
                urlLogo: 'assets/logo/mongo.png',
            },
            {
                name: 'Kong Gateway',
                urlLogo: 'assets/logo/kong.png'
            },
            {
                name: 'Docker',
                urlLogo: 'assets/logo/docker.png',
            },
            {
                name: 'Kubernetes',
                urlLogo: 'assets/logo/k8s.png'
            },
            {
                name: 'Git',
                urlLogo: 'assets/logo/git.png',
            },
        ]
    },
    {
        title: 'Pointyh.at',
        description: 'Site web dans l\'univers fantastique du sorcier aux lunettes rondes. Pour n\'importe quel célébrité de notre monde jouez le rôle du Pointy Hat en leur choisissant une maison de sorcier, puis découvrez le pourcentage de personnes ayant choisi comme vous.',
        urlVideo: 'assets/video/pointyhat.mp4',
        urlImage: '',
        type: 'phone',
        logo: 'assets/logo/pointyhat.png',
        team: ' - ',
        urlSourceCode: '',
        url: 'https://www.pointyh.at',
        tool: [
            {
                name: 'Typescript',
                urlLogo: 'assets/logo/ts.png',
            },
            {
                name: 'NestJS',
                urlLogo: 'assets/logo/nest.png',
            },
            {
                name: 'NPM',
                urlLogo: 'assets/logo/npm.png',
            },
            {
                name: 'REST API',
                urlLogo: 'assets/logo/rest.png',
            },
            {
                name: 'Figma',
                urlLogo: 'assets/logo/figma.png',
            },
            {
                name: 'React Native',
                urlLogo: 'assets/logo/react.png',
            },
            {
                name: 'MongoDB',
                urlLogo: 'assets/logo/mongo.png',
            },
            {
                name: 'Docker',
                urlLogo: 'assets/logo/docker.png',
            },
            {
                name: 'Git',
                urlLogo: 'assets/logo/git.png',
            },
        ]
    },
    {
        title: 'Market Place (en cours)',
        description:    'Market place d\'échange de NFT normalisés ERC721. Cette application a nécessité la publication d\'un smart contract sur le réseau Ethereum fournissant les interfaces necessaires à la manipulation des jetons. L\'application intéragie avec la Blockchain pour récupérer les informations des jetons ce qui lui permet d\'être décentralisée.',
        urlVideo: 'assets/video/market-place.mp4',
        urlImage: '',
        type: 'laptop',
        logo: 'assets/logo/nft.png',
        team: ' - ',
        urlSourceCode: '',
        url: '',
        tool: [
            {
                name: 'Typescript',
                urlLogo: 'assets/logo/ts.png',
            },
            {
                name: 'NestJS',
                urlLogo: 'assets/logo/nest.png',
            },
            {
                name: 'Jest',
                urlLogo: 'assets/logo/jest.png',
            },
            {
                name: 'NPM',
                urlLogo: 'assets/logo/npm.png',
            },
            {
                name: 'REST API',
                urlLogo: 'assets/logo/rest.png',
            },
            {
                name: 'Figma',
                urlLogo: 'assets/logo/figma.png',
            },
            {
                name: 'React',
                urlLogo: 'assets/logo/react.png',
            },
            {
                name: 'MongoDB',
                urlLogo: 'assets/logo/mongo.png',
            },
            {
                name: 'Docker',
                urlLogo: 'assets/logo/docker.png',
            },
            {
                name: 'Git',
                urlLogo: 'assets/logo/git.png',
            },
            {
                name: 'Ethereum',
                urlLogo: 'assets/logo/eth.png',
            },
            {
                name: 'Solidity',
                urlLogo: 'assets/logo/solidity.png',
            },
            {
                name: 'Web3JS',
                urlLogo: 'assets/logo/web3.jpg',
            },
            {
                name: 'Ganache',
                urlLogo: 'assets/logo/ganache.png',
            },
        ]
    },
    {
        title: 'Binance Market Bot',
        description:    'Bot temps réel Binance. Ce bot analyse le marché crypto en temps réel et peut éxécuter des ordres afin de profiter de potentiels écarts de valeur entre certaines paires d\'échanges.',
        urlVideo: 'assets/video/market-bot.mp4',
        urlImage: '',
        type: 'laptop',
        logo: 'assets/logo/binance.png',
        team: 'Timothée Benoist',
        urlSourceCode: '',
        url: '',
        tool: [
            {
                name: 'Javascript',
                urlLogo: 'assets/logo/js.png',
            },
            {
                name: 'NodeJS',
                urlLogo: 'assets/logo/node.png',
            },
            {
                name: 'NPM',
                urlLogo: 'assets/logo/npm.png',
            },
            {
                name: 'WebSocket',
                urlLogo: 'assets/logo/ws.png',
            },
            {
                name: 'Vue',
                urlLogo: 'assets/logo/vue.png',
            },
        ]
    },
    {
        title: 'Détection automatique de variabilité',
        description:    'Etude avec le pôle recherche de l\'université de Nice Côte d\'Azur sur l\'application du paradigm de la ligne de produit dans les projets OpenSource. ' +
                        'Cette étude propose une méthode de détection automatique de variabilité dans des projets OpenSource écrit en Javascript',
        urlVideo: 'assets/video/ter.mp4',
        urlImage: '',
        type: 'laptop',
        logo: 'assets/logo/uca.png',
        team: 'Philippe Collet, Johann Mortara',
        urlSourceCode: '',
        url: 'assets/pdf/TER_27_scientific_article_BRUEL_Martin.pdf',
        tool: [
            {
                name: 'Typescript',
                urlLogo: 'assets/logo/ts.png',
            },
            {
                name: 'npm',
                urlLogo: 'assets/logo/npm.png',
            },
            {
                name: 'neo4j',
                urlLogo: 'assets/logo/neo4j.png',
            },
            {
                name: 'docker',
                urlLogo: 'assets/logo/docker.png',
            },
            {
                name: 'git',
                urlLogo: 'assets/logo/git.png',
            }
        ]
    },
    {
        title: 'Simulateur de livraison de drone',
        description:    'Ce simulateur de livraison de drone a été créé dans le cadre de la spécialité architecture logiciel. Ce simulateur avait pour but de mettre en ' +
                        'application des principes d\'architecture micro-service en interconnectant des entités pouvant évoluer de manière autonome dans un environnement inconnu',
        urlVideo: 'assets/video/drone-delivery.mp4',
        urlImage: '',
        type: 'laptop',
        logo: 'assets/logo/drone-delivery.jpg',
        team: 'Alexandre Col, Thibaut Esteve, David Lebrisse, Nathan Meulle, Kévin Ushaka',
        urlSourceCode: 'https://github.com/Martin-Bruel/al-drone-g',
        url: '',
        tool : [
            {
                name: 'Javascript',
                urlLogo: 'assets/logo/js.png',
            },
            {
                name: 'NodeJS',
                urlLogo: 'assets/logo/node.png',
            },
            {
                name: 'NPM',
                urlLogo: 'assets/logo/npm.png',
            },
            {
                name: 'Java',
                urlLogo: 'assets/logo/java.png',
            },
            {
                name: 'Spring',
                urlLogo: 'assets/logo/spring.png',
            },
            {
                name: 'Maven',
                urlLogo: 'assets/logo/maven.png',
            },
            {
                name: 'MongoDB',
                urlLogo: 'assets/logo/mongo.png',
            },
            {
                name: 'Docker',
                urlLogo: 'assets/logo/docker.png',
            },
            {
                name: 'Git',
                urlLogo: 'assets/logo/git.png',
            },
            {
                name: 'Python',
                urlLogo: 'assets/logo/py.png',
            },
            {
                name: 'Vue',
                urlLogo: 'assets/logo/vue.png',
            },
            {
                name: 'REST API',
                urlLogo: 'assets/logo/rest.png',
            },
            {
                name: 'WebSocket',
                urlLogo: 'assets/logo/ws.png',
            }
        ]
    },
    // {
    //     title: 'Polyville Active',
    //     description:    'Polyville a été développer dans le cadre d\'un projet d\'innovation de fin de semestre à POLYTECH. Il s\'agit d\'une application mobile couplée ' +
    //                     'à un système d’API embarqué proposant aux usagers la découverte dynamique de commerces locaux alentours.',
    //     urlVideo: 'assets/video/polyville-active.mp4',
    //     urlImage: '',
    //     type: 'laptop',
    //     logo: 'assets/logo/polyville-active.png',
    //     team: 'Nicolas Lanoux,  Maëva Lecavelier, Florian Striebel',
    //     urlSourceCode: 'https://github.com/Martin-Bruel/ps7-20-21-al-iam1',
    //     url: ''  
    // },
    {
        title: 'STO aliment',
        description:    'STO aliment a été développé pour un projet d\'IHM mené à POLYTECH. ' + 
                        'Cette application mobile a pour but de permettre aux utilisateurs de collecter de bonnes ' + 
                        'adresses de producteurs afin d’avoir accès à des produits locaux.',
        urlVideo: 'assets/video/sto.mp4',
        urlImage: '',
        type: 'phone',
        logo: 'assets/logo/sto.png',
        team: 'Rachid El Adlani,  Abdelouhab Belkhiri, Lucas Dominguez',
        urlSourceCode: 'https://github.com/rachid-eladlani/sto_aliments',
        url: '',
        tool: [
            {
                name: 'Java',
                urlLogo: 'assets/logo/java.png',
            },
            {
                name: 'Android Studio',
                urlLogo: 'assets/logo/android.png',
            },
            {
                name: 'Firebase',
                urlLogo: 'assets/logo/firebase.png',
            }
        ]
    },
    // {
    //     title: 'Quiz-r6',
    //     description:    'Ce projet a été rélisé dans le cadre personnel. Quiz-r6 est un chat-bot sur discord avec pour thème les cartes du jeu Rainbow Six Siege. L\'utilisateur peut jouer au quiz directement depuis l\'interface de ' + 
    //                     'discord.',
    //     urlVideo: 'assets/video/quiz-r6.mp4',
    //     urlImage: '',
    //     type: 'laptop',
    //     logo: 'assets/logo/quizr6.png',
    //     team: 's0me6uy, Victor Bruel',
    //     urlSourceCode: '',
    //     url: '',
    // },
    {
        title: 'QuizTalin',
        description:    'QuizTalin est une application web réalisée pour le projet de semestre 6 à POLYTECH. Elle a pour but de permettre à des utilisateurs de ' + 
                        'configurer un quiz adapté à des personnes vieillissantes ayant des difficultés pour utiliser un outil ' + 
                        'informatique. QuizTalin se concentre sur les handicaps liés à la vision.',
        urlVideo: 'assets/video/quiztalin.mp4',
        urlImage: '',
        type: 'laptop',
        logo: 'assets/logo/quiztalin.png',
        team: 'Alexandre Col, Romain Lamere, Yann Léon',
        urlSourceCode: 'https://github.com/2019-2020-ps6/2019-2020-ps6-rendu-army',
        url: '',
        tool:[
            {
                name: 'Javascript',
                urlLogo: 'assets/logo/js.png',
            },
            {
                name: 'Typescript',
                urlLogo: 'assets/logo/ts.png',
            },
            {
                name: 'Angular',
                urlLogo: 'assets/logo/angular.png',
            },
            {
                name: 'NodeJS',
                urlLogo: 'assets/logo/node.png',
            },
            {
                name: 'NPM',
                urlLogo: 'assets/logo/npm.png',
            }
        ]
    },
    // {
    //     title: '4AXES Manager',
    //     description:    '4AXES Manager est un logiciel d\'analyse de données de traitement sur des documents médicaux. ' + 
    //                     'Ce projet a été réalisé lors d\'une mission dans l\'entreprise 4AXES.',
    //     urlVideo: '',
    //     urlImage: 'assets/image/4axes-manager.PNG',
    //     type: 'laptop',
    //     logo: 'assets/logo/4axes.jpg',
    //     team: ' - ',
    //     urlSourceCode: '',
    //     url:'',
    //     tool : [
    //         {
    //             name: 'Qt',
    //             urlLogo: 'assets/logo/qt.png',
    //         },
    //         {
    //             name: 'SQL',
    //             urlLogo: 'assets/logo/sql.png',
    //         },
    //         {
    //             name: 'MySQL',
    //             urlLogo: 'assets/logo/mysql.png',
    //         }
    //     ]
    // }
    
];