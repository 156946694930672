import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

    public videoIds : number[] = [];

    constructor() { }

    addVideoId(id : number){
        this.videoIds.push(id);
    }

    getIndex(id : number) {
        return document.querySelectorAll('video')[this.videoIds.indexOf(id)]
    }
}