import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from 'src/services/contact.service';
import { ContactAnimation } from 'src/app/animations';

@Component({
  selector: 'app-contact-review',
  templateUrl: './contact-review.component.html',
  styleUrls: ['../contact.scss'],
  animations: [
    ContactAnimation.inOutLoader,
    ContactAnimation.inButton
  ]
})
export class ContactReviewComponent implements OnInit {

  checkoutForm : FormGroup;
  formSubmitted = false;
  loading = false;

  constructor(private formBuilder : FormBuilder, private contactService : ContactService) {

    this.checkoutForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      mail: [null, [Validators.required, Validators.email]],
      body: [null],
    })

    contactService.loading$.subscribe( (loading) => {

      this.loading = loading;
      if(!loading){
        this.checkoutForm.reset();
        this.formSubmitted = false;
      }
    });

    
  }

  ngOnInit() {
  }

  onSubmit(data){

    this.formSubmitted = true;

    if(this.checkoutForm.valid){

      this.contactService.sendMessage(data, 'Review');
    }
  }

  validation(id :string){
    if(!this.checkoutForm.controls[id].valid && this.formSubmitted)
      return 'invalide';
    return ' ';
  }

}
